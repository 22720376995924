import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import { DeepPartial } from 'utility-types'

import { KnowledgeChunk } from 'apps/aiAlwaysOnAutomations/constants/types'
import { alert } from 'common/core'
import { AiAutomationApi } from 'shared/api/requests/aiAutomation'
import { AiAutomationConfig, BusinessContextChunk } from 'shared/api/requests/aiAutomation/schemas'
import { createAsyncAction } from 'shared/lib/redux'

const NAMESPACE = 'aiAlwaysOnAutomations'

export enum LoaderStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADED = 'LOADED',
  DASHBOARD_LOADING = 'DASHBOARD_LOADING',
  FAQ_LIVE_LOADING = 'FAQ_LIVE_LOADING',
  KNOWLEDGE_EDITOR_LOADING = 'KNOWLEDGE_EDITOR_LOADING',
}

export interface AiAlwaysOnAutomationsState {
  config: AiAutomationConfig
  selectedKnowledgeChunkIndex: number | null
  loaderStatus: LoaderStatus
}

export const AiAlwaysOnAutomationsInitialState: AiAlwaysOnAutomationsState = {
  config: {
    business_context: [],
    scenarios: {
      faq: {
        published: false,
      },
    },
  },
  selectedKnowledgeChunkIndex: null,
  loaderStatus: LoaderStatus.NOT_LOADED,
}

const { reducer, actions } = createSlice({
  initialState: AiAlwaysOnAutomationsInitialState,
  name: NAMESPACE,
  reducers: {
    dataUpdated: (state, action: PayloadAction<AiAutomationConfig>) => {
      state.config = action.payload
    },
    selectedKnowledgeChunkIndexUpdated: (state, action: PayloadAction<number | null>) => {
      state.selectedKnowledgeChunkIndex = action.payload
    },
    loaderStatusUpdated: (state, action: PayloadAction<LoaderStatus>) => {
      state.loaderStatus = action.payload
    },
  },
})

export const AiAlwaysOnAutomationsReducer = reducer

const getState = (state: RootState) => state.aiAlwaysOnAutomations

export const AiAlwaysOnAutomationsSelectors = {
  getState,
  getBusinessContext: (state: RootState) => getState(state).config.business_context,
  getSelectedKnowledgeChunkIndex: (state: RootState) => getState(state).selectedKnowledgeChunkIndex,
  getSelectedKnowledgeChunk: (state: RootState) => {
    const index = getState(state).selectedKnowledgeChunkIndex
    if (index === null) {
      return undefined
    }
    return getState(state).config.business_context[index]
  },
  getFaqScenarioStatus: (state: RootState) => getState(state).config.scenarios.faq.published,
  getConfig: (state: RootState) => getState(state).config,
  getLoaderStatus: (state: RootState) => getState(state).loaderStatus,
}

const getConfigFx = createAsyncAction(`${NAMESPACE}/getConfig`, async (_, { dispatch }) => {
  dispatch(actions.loaderStatusUpdated(LoaderStatus.DASHBOARD_LOADING))

  const { data } = await AiAutomationApi.getConfig()
  dispatch(actions.dataUpdated(data.config))
  dispatch(actions.loaderStatusUpdated(LoaderStatus.LOADED))
})

const updateConfigFx = createAsyncAction(
  `${NAMESPACE}/updateConfig`,
  async (changes: DeepPartial<AiAutomationConfig>, { dispatch, getState }) => {
    const config = AiAlwaysOnAutomationsSelectors.getConfig(getState())
    const newScenarios = merge({}, config.scenarios, changes.scenarios)
    const newConfig: AiAutomationConfig = {
      scenarios: newScenarios,
      business_context: (changes.business_context ||
        config.business_context) as BusinessContextChunk[],
    }

    await AiAutomationApi.updateConfig({
      body: { config: newConfig },
    })

    dispatch(actions.dataUpdated(newConfig))
    dispatch(actions.loaderStatusUpdated(LoaderStatus.LOADED))
  },
)

const removeChunkFx = createAsyncAction(
  `${NAMESPACE}/removeChunkFx`,
  async (removedChunkIndex: number, { dispatch, getState }) => {
    const config = AiAlwaysOnAutomationsSelectors.getConfig(getState())
    const updatedContext = config.business_context.filter((_, index) => index !== removedChunkIndex)

    const newConfig = {
      ...config,
      business_context: updatedContext,
    }

    await dispatch(updateConfigFx(newConfig))
  },
)

const editKnowledgeChunkFx = createAsyncAction(
  `${NAMESPACE}/editKnowledgeChunkFx`,
  async (data: KnowledgeChunk, { dispatch, getState }) => {
    const config = AiAlwaysOnAutomationsSelectors.getConfig(getState())
    const index = AiAlwaysOnAutomationsSelectors.getSelectedKnowledgeChunkIndex(
      getState(),
    ) as number

    const newBusinessContext = [...config.business_context]
    newBusinessContext[index] = data

    dispatch(actions.loaderStatusUpdated(LoaderStatus.KNOWLEDGE_EDITOR_LOADING))
    await dispatch(updateConfigFx({ business_context: newBusinessContext }))
    dispatch(actions.selectedKnowledgeChunkIndexUpdated(null))
    alert('Chunk was been edited', 'success')
  },
  {
    condition: (editedChunk: KnowledgeChunk, { getState }) => {
      const selectedChunk = AiAlwaysOnAutomationsSelectors.getSelectedKnowledgeChunk(getState())

      if (!selectedChunk) {
        return false
      }

      return selectedChunk.content !== editedChunk.content
    },
  },
)

const addKnowledgeChunkFx = createAsyncAction(
  `${NAMESPACE}/addKnowledgeChunkFx`,
  async (data: KnowledgeChunk, { dispatch, getState }) => {
    const config = AiAlwaysOnAutomationsSelectors.getConfig(getState())

    dispatch(actions.loaderStatusUpdated(LoaderStatus.KNOWLEDGE_EDITOR_LOADING))
    await dispatch(updateConfigFx({ business_context: [data, ...config.business_context] }))
    alert('Chunk was been added', 'success')
  },
)

const updateFaqScenarioStatusFx = createAsyncAction(
  `${NAMESPACE}/updateFaqScenarioStatusFx`,
  async (status: boolean, { dispatch }) => {
    dispatch(actions.loaderStatusUpdated(LoaderStatus.FAQ_LIVE_LOADING))
    await dispatch(
      updateConfigFx({
        scenarios: {
          faq: {
            published: status,
          },
        },
      }),
    )
  },
)

export const AiAlwaysOnAutomationsActions = {
  removeChunkFx,
  selectedKnowledgeChunkIndexUpdated: actions.selectedKnowledgeChunkIndexUpdated,
  editKnowledgeChunkFx,
  addKnowledgeChunkFx,
  updateFaqScenarioStatusFx,
  getConfigFx,
}
